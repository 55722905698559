import React, { useState } from 'react';
import { makeStyles, Container, Grid, Box } from '@material-ui/core';
import {
  Search,
  BackNavAdmin,
  DialogSendIdea,
  DialogTitleDescription,
  DialogArea,
  DialogChallenge,
  DialogPhoto,
  DialogVideo,
  DialogFile,
  DialogLink,
  DialogCanvas,
  DialogValueCanvas,
  DialogMultiArea,
  DialogClassification,
  MyIdeasFilterMenu,
  SkeletonTable,
  EmptyState
} from '../../components';
import { IdeaCard } from '../../components/Idea';
import { DialogDeleteDraft, Loading } from '../../components';
import { IdeaTable } from '../../components/Tables/IdeaTable';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { myIdeasIcon } from '../../assets/imgs';
import { useMyIdeasController } from './hooks/useMyIdeasController';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  filter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem'
  },
  containerLoading: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function IdeaPage(props) {
  const classes = useStyles();
  const [openDialog, setOpentDialog] = useState(false);
  const [idea, setIdea] = useState({});
  const { t } = useTranslation();
  const { loadMoreIdeas, searchIdea, ideas, loading, paging } = useMyIdeasController();
  const hasIdeas = ideas?.length > 0;

  const dialogs = (
    <Box>
      <DialogTitleDescription />
      <DialogArea />
      <DialogMultiArea />
      <DialogChallenge />
      <DialogSendIdea isEdit={true} />
      <DialogPhoto />
      <DialogVideo />
      <DialogFile />
      <DialogLink />
      <DialogCanvas />
      <DialogValueCanvas />
      <DialogClassification />
    </Box>
  );






  function renderCards() {
    if (ideas.length > 0) {
      return (
        <InfiniteScroll
          dataLength={ideas.length}
          next={loadMoreIdeas}
          hasMore={true}
          scrollThreshold={'100%'}
          loader={
            loading && (
              <Box className={classes.containerLoading}>
                <Loading />
              </Box>
            )
          }
        >
          {ideas.map((idea, index) => (
            <IdeaCard
              index={index}
              idea={idea}
              setIdea={setIdea}
              key={index}
              history={props.history}
              dialog={{
                setOpentDialog: setOpentDialog,
                openDialog: openDialog,
              }}
            />
          ))}
        </InfiniteScroll>
      );
    }

    return null;
  }

  function closeDialog() {
    setOpentDialog(false);
    setIdea({});
  }

  return (
    <>
      <BackNavAdmin title={t('minhas_ideias')} pathname="/" />
    <Container maxWidth="lg" className={classes.root}>
      {dialogs}
      <DialogDeleteDraft open={openDialog} idea={idea} onClose={closeDialog} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
      <Box className={classes.filter}>
        <MyIdeasFilterMenu />
        <Search
          query={searchIdea}
          placeholder={t('pesquise_por_nome_da_ideia_ou_desafio')}
        />
      </Box>
        </Grid>
        <Grid item xs={12}>
            {loading && <SkeletonTable cols={7} />}
            {(!loading && !hasIdeas) && (
              <EmptyState
                icon={myIdeasIcon}
                title={t('voce_ainda_nao_publicou_nenhuma_ideia')}
                description={t('que_tal_compartilhar_em_qual_ideia_voce_esta_pensando')}
              />
            )}
            {(!loading && hasIdeas) && (
              <IdeaTable
                idea={idea}
                setIdea={setIdea}
                ideas={ideas}
                paging={paging}
                history={props.history}
                dialog={{ setOpentDialog: setOpentDialog, openDialog: openDialog }}
              />
          )}
        </Grid>
      </Grid>
    </Container>
    </>
  );
}

export { IdeaPage };
