export const fluxOfIdeasConstants = {
  CREATE_IDEA_IN_FLUX_REQUEST: 'CREATE_IDEA_IN_FLUX_REQUEST',
  CREATE_IDEA_IN_FLUX_SUCCESS: 'CREATE_IDEA_IN_FLUX_SUCCESS',
  CREATE_IDEA_IN_FLUX_FAILURE: 'CREATE_IDEA_IN_FLUX_FAILURE',


  UPDATE_IDEA_STAGE_IN_FLUX_REQUEST: 'UPDATE_IDEA_STAGE_IN_FLUX_REQUEST',
  UPDATE_IDEA_STAGE_IN_FLUX_SUCCESS: 'UPDATE_IDEA_STAGE_IN_FLUX_SUCCESS',
  UPDATE_IDEA_STAGE_IN_FLUX_FAILURE: 'UPDATE_IDEA_STAGE_IN_FLUX_FAILURE',


  GET_USER_IDEAS_IN_FLUX_REQUEST: 'GET_USER_IDEAS_IN_FLUX_REQUEST',
  GET_USER_IDEAS_IN_FLUX_SUCCESS: 'GET_USER_IDEAS_IN_FLUX_SUCCESS',
  GET_USER_IDEAS_IN_FLUX_FAILURE: 'GET_USER_IDEAS_IN_FLUX_FAILURE',

  SET_MANAGER_OBSERVATION: 'SET_MANAGER_OBSERVATION',
  CLEAR_FLUX_OF_IDEAS: 'CLEAR_FLUX_OF_IDEAS',
  SET_EVALUATION_RATES_IN_FLUX_IDEA: 'SET_EVALUATION_RATES_IN_FLUX_IDEA',
  GET_IDEA_DETAILS_IN_FLUX_REQUEST: 'GET_IDEA_DETAILS_IN_FLUX_REQUEST',
  GET_IDEA_DETAILS_IN_FLUX_SUCCESS: 'GET_IDEA_DETAILS_IN_FLUX_SUCCESS',
  GET_IDEA_DETAILS_IN_FLUX_FAILURE: 'GET_IDEA_DETAILS_IN_FLUX_FAILURE',
  TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_REQUEST: 'TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_REQUEST',
  TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_SUCCESS: 'TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_SUCCESS',
  TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_FAILURE: 'TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_FAILURE',
}