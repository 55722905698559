import { fluxOfIdeasConstants, ideaCreateConstans, ideaGeneralEvaluationConstants } from '../constants';
import { challengeActions } from '../actions';
import { fluxOfIdeasService, ideaGeneralEvaluationService, ideaService } from '../services';
import { alertActions } from './alert.actions';
import { getFiles } from '../helpers';

export const fluxOfIdeasActions = {
  createIdeaInFlux,
  getIdeaInFlux,
  updateIdeaStageInFlux,
  getEvaluationsByGroup,
  getIdeasUserStepFlux,
  setManagerObservation,
  clearFluxOfIdeas,
  uploadFluxIdeasFiles,
  ideaVisibleInTimeline,
  setEvaluationRate,
}

function createIdeaInFlux(idea, successMessage) {
  const contributors = idea.contributors.map(({ id }) => id);
  idea.contributors = contributors;
  return async dispatch => {
    dispatch({ type: fluxOfIdeasConstants.CREATE_IDEA_IN_FLUX_REQUEST });
    try {
      const ideaResponse = await fluxOfIdeasService.createIdeaInFlux(idea);
        dispatch({ type: fluxOfIdeasConstants.CREATE_IDEA_IN_FLUX_SUCCESS });
        dispatch(alertActions.success(successMessage));
        const files = getFiles(idea);
      await ideaService.uploadFiles(files, ideaResponse.data.data._id);
      dispatch({ type: ideaCreateConstans.CLEAR });
        if (idea.ideaCreateChallenge) {
          dispatch(challengeActions.getChallengeById(idea.challenge._id));
        }
    } catch(error) {
        dispatch({
          type: fluxOfIdeasConstants.CREATE_IDEA_IN_FLUX_FAILURE,
          error: error.toString()
        });
    }
  }
}

function getIdeaInFlux(id) {
  return dispatch => {
    dispatch({ type: fluxOfIdeasConstants.GET_IDEA_DETAILS_IN_FLUX_REQUEST });
    fluxOfIdeasService.getIdeaInFlux(id)
      .then((idea) => {
        dispatch({
          type: fluxOfIdeasConstants.GET_IDEA_DETAILS_IN_FLUX_SUCCESS,
          payload: { idea }
        });
      })
      .catch((error) => {
        dispatch({
          type: fluxOfIdeasConstants.GET_IDEA_DETAILS_IN_FLUX_FAILURE,
          error: error.toString()
        });
      })
  }
}

function updateIdeaStageInFlux(ideaId, body, successMessage, attachmentRequired, files) {
  const filesFiltered = files && files.filter(item => item.id);
  return async dispatch => {
    dispatch({ type: fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_REQUEST });
    try {
    if (attachmentRequired) {
        await fluxOfIdeasService.uploadFiles(filesFiltered, ideaId);
      }
      const { data } = await fluxOfIdeasService.updateIdeaStageInFlux(ideaId, body);
              dispatch({
                type: fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_SUCCESS,
                payload: data.data
              });
              dispatch(alertActions.success(successMessage));
              dispatch(clearFluxOfIdeas());
              dispatch(getIdeaInFlux(ideaId));
    } catch (error) {
      const message = error.message;

      if (error.response && error.response.data.errors) {
        message = error.response.data.errors.substring(7);
      }

          dispatch({ type: fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_FAILURE });
      await dispatch(alertActions.error(message));
    }

  }
}

function getEvaluationsByGroup(groupId) {
  return dispatch => {
    dispatch({ type: ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_REQUEST });
    ideaGeneralEvaluationService.getEvaluationsByGroup(groupId)
      .then(({ data }) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_SUCCESS,
          payload: data.data
        });
      })
      .catch((error) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_FAILURE,
          error: error
        });
      })
  }
}

function getIdeasUserStepFlux(page, sort, query, limit, stepsIdeas) {
  return dispatch => {
    dispatch({ type: fluxOfIdeasConstants.GET_USER_IDEAS_IN_FLUX_REQUEST });
    fluxOfIdeasService.getIdeasUserStepFlux(page, sort, query, limit, stepsIdeas)
      .then((ideasFlux) => {
        dispatch({
          type: fluxOfIdeasConstants.GET_USER_IDEAS_IN_FLUX_SUCCESS,
          payload: { ideasFlux, query }
        });
      })
      .catch((error) => {
        console.log(error, 'error')
        dispatch({ type: fluxOfIdeasConstants.GET_USER_IDEAS_IN_FLUX_FAILURE });
      })
  }
}

function setManagerObservation(value) {
  return dispatch => {
    dispatch({
      type: fluxOfIdeasConstants.SET_MANAGER_OBSERVATION,
      payload: { value }
    })
  }
}

function clearFluxOfIdeas() {
  return dispatch => {
    dispatch({ type: fluxOfIdeasConstants.CLEAR_FLUX_OF_IDEAS })
  }
}

function uploadFluxIdeasFiles(files, ideaId) {
  return dispatch => {
    fluxOfIdeasService.uploadFiles(files, ideaId).then(() => { }).catch(error => console.error(error))
  }
}

function ideaVisibleInTimeline(id, checked) {
  return dispatch => {
    dispatch({
      type: fluxOfIdeasConstants.TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_REQUEST,
      payload: checked
    });
    fluxOfIdeasService.ideaVisibleInTimeline(id, checked).then(
      (response) => {
        dispatch({
          type: fluxOfIdeasConstants.TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          type: fluxOfIdeasConstants.TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_FAILURE,
          error
        });
      }
    );
  }
}

function setEvaluationRate(index, rate) {
  return dispatch => {
    dispatch({
      type: fluxOfIdeasConstants.SET_EVALUATION_RATES_IN_FLUX_IDEA,
      payload: { index, rate } 
    });
  }
}

