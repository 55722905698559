import axios from 'axios';
import config from '../config';
import { requestHeaders, handleResponse } from '../helpers';

export const fluxOfIdeasService = {
  createIdeaInFlux,
  getIdeaInFlux,
  updateIdeaStageInFlux,
  uploadFiles,
  getAllEvaluationsByGroup,
  ideaVisibleInTimeline,
  getIdeasUserStepFlux
}

async function createIdeaInFlux(data) {
  const response = await axios.post(`${config.URL_BASE}/ideas/by-step-flux`, data, requestHeaders());
  return response.data;
}

function getIdeaInFlux(ideaId) {
  return axios
    .get(`${config.URL_BASE}/ideas/by-step-flux/${ideaId}`, requestHeaders())
    .then((response) => response.data.data);
}

async function updateIdeaStageInFlux(ideaId, data) {
  const response = await axios.put(`${config.URL_BASE}/ideas/by-step-flux/update-step/${ideaId}`, data, requestHeaders());
  
  return response;
}

function uploadFiles(files, ideaId) {
  if (files && files.length > 0) {
    const promises = files.map((file) => {
      const data = new FormData();

      data.append('file', file.file);

      return axios.post(`${config.URL_BASE}/uploads/idea/by-step-flux/${ideaId}`, data, requestHeaders());
    });

    return Promise.all(promises);
  }

  return;
}

function getAllEvaluationsByGroup(id) {
  return axios
    .get(`${config.URL_BASE}/personalized-evaluation/by-group/${id}`, requestHeaders());
}

function ideaVisibleInTimeline(id, status) {
  return axios
    .put(`${config.URL_BASE}/ideas/${id}/timeline`, {  timeline: status }, requestHeaders())
    .then(handleResponse);
}

function getIdeasUserStepFlux(page, sort, query, limit, stepsIdeas) {
  const params = `?q=${query}&page=${page}&sort=${JSON.stringify(sort)}&quantityPerPage=${limit}&stepsIdeas=${JSON.stringify(stepsIdeas)}`;

  return axios
    .get(`${config.URL_BASE}/ideas/user-by-step-flux${params}`, requestHeaders())
    .then(handleResponse);
}