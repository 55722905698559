import React, { useMemo } from 'react';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { ideaCreateActions } from '../../actions';
import { ideaUpdateActions } from '../../actions/ideaUpdate.actions';
import { VideocamOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  removeButton: {
    top: 0,
    left: 0,
  },
}));

function VideoPreview({ video, isEdit = false, onRemove=ideaCreateActions.removeVideo }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  function removeVideo() {
    if (isEdit) {
      dispatch(ideaUpdateActions.removeVideo(video.id ? video.id : video._id));
    } else {
      dispatch(onRemove());
    }
  }

  const isMp4Video = useMemo(() => video ? video.value?.endsWith('.mp4') : "", [video]);
  const filterYTUrl = useMemo(()=> video && video.value?.match(/v=(.*)&/gm) !== null ? video.value?.match(/v=(.*)&/gm)[0]?.split('v=')[1]?.split('&')[0] : "", [video])

  return (
    <div style={{ width: '100%', position: 'relative' }} key={video?.id}>
     <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Box display='flex' alignItems='center'>
                  <VideocamOutlined />
                  <Box marginLeft={2}>
                    <Typography variant='subtitle2'>{video?.name}</Typography>
                    <Typography variant='caption'>{video?.readableSize}</Typography>
                  </Box>
                </Box>
                <IconButton
                  className={classes.removeButton}
                  onClick={removeVideo}
                >
        <DeleteIcon />
      </IconButton>
              </Box>
      {isMp4Video || !!video?.preview?.length ? <video style={{ width: '100%' }} height="255" controls>
        <source
          src={video.value ? video.value : video.preview}
          type={video.type}
        />
  
      </video> :
        <iframe width="470" height="255" src={`https://www.youtube.com/embed/${filterYTUrl}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
    </div>
  );
}

export { VideoPreview };
