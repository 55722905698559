import { fluxOfIdeasConstants, ideaGeneralEvaluationConstants } from '../constants';

const initialState = {
  ideaDetailsFlux: {},
  ideasFlux: [],
  managerObservation: '',
  evaluationInFluxIdea: [],
  paging: {
    page: 1,
    sort: { created: -1 },
    limit: 10,
    query: '',
    total: 0
  },
  loadingFlux: false,
  loadingUpdate: false
};

export function fluxIdeas(state = initialState, action) {
  switch (action.type) {
    case fluxOfIdeasConstants.GET_USER_IDEAS_IN_FLUX_REQUEST:
      return {
        ...state,
        loadingFlux: true,
      }
    case fluxOfIdeasConstants.GET_USER_IDEAS_IN_FLUX_SUCCESS:
      return {
        ...state,
        ideasFlux: action.payload.ideasFlux.data,
        paging: {
          page: parseInt(action.payload.ideasFlux.paging.page),
          query: action.payload.query,
          limit: action.payload.ideasFlux.paging.limit,
          sort: action.payload.ideasFlux.paging.sort,
          total: action.payload.ideasFlux.paging.total,
        },
        loadingFlux:  false
      }
    case fluxOfIdeasConstants.GET_USER_IDEAS_IN_FLUX_FAILURE:
      return {
        ...state,
        loadingFlux: false,
      }
    case fluxOfIdeasConstants.SET_MANAGER_OBSERVATION:
      return {
        ...state,
        managerObservation: action.payload.value
      }

    case fluxOfIdeasConstants.CLEAR_FLUX_OF_IDEAS:
      return {
        ...state,
        managerObservation: ''
      }

    case fluxOfIdeasConstants.SET_EVALUATION_RATES_IN_FLUX_IDEA:
      return {
        ...state,
        // evaluationInFluxIdea: state.evaluationInFluxIdea.map((item, index) => {
        //   if (index === action.payload.rate) {
        //     return {
        //       ...item,
        //       rate: action.payload.rate
        //     }
        //   }
        // }),
      }

    case fluxOfIdeasConstants.TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_REQUEST:
      return {
        ...state,
        ideaDetailsFlux: {
          ...state.ideaDetailsFlux,
          timeline: action.payload
        },
        loadingFlux: true,
      }
    case fluxOfIdeasConstants.TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_SUCCESS: 
      return {
        ...state,
        loadingFlux: false,
      }
    case fluxOfIdeasConstants.TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_FAILURE:
      return {
        ...state,
        loadingFlux: false,
      }
    case fluxOfIdeasConstants.GET_IDEA_DETAILS_IN_FLUX_REQUEST:
    case ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_REQUEST:
      return {
        ...state,
        loadingFlux: true
      }

    case fluxOfIdeasConstants.GET_IDEA_DETAILS_IN_FLUX_SUCCESS:
      return {
        ...state,
        ideaDetailsFlux: action.payload.idea,
        loadingFlux: false
      }

    case ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_SUCCESS:
      return {
        ...state,
        evaluationInFluxIdea: action.payload.map(item => {
          return {
            personalizedEvaluation: item,
            rate: 1
          }
        }),
        loadingFlux: false
      }

    case fluxOfIdeasConstants.GET_IDEA_DETAILS_IN_FLUX_FAILURE:
    case ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_FAILURE:
      return {
        ...state,
        loadingFlux: false
      }

    case fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_REQUEST:
      return {
        ...state,
        loadingUpdate: true
      }

    case fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_SUCCESS:
      return {
        ...state,
        loadingUpdate: false
      }

    case fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_FAILURE:
      return {
        ...state,
        loadingUpdate: false
      }

    case fluxOfIdeasConstants.CREATE_IDEA_IN_FLUX_REQUEST:
      return {
        ...state,
        loadingFlux: true
      }
    case fluxOfIdeasConstants.CREATE_IDEA_IN_FLUX_SUCCESS:
      return {
        ...state,
        loadingFlux: false
      }
    case fluxOfIdeasConstants.CREATE_IDEA_IN_FLUX_FAILURE:
      return {
        ...state,
        loadingFlux: false
      }
    default: {
      return state;
    }
  }
}
